body {
  margin: 0;
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f9fc;
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

main {
    flex: 1 0 auto;
}

.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 95%;
}

.shadow {
  box-shadow: 0 4px 20px 0 rgba(194, 194, 194, 0.4);
  width: 100%;
}

.brand-logo {
  color:  #0a0a4e!important;
}

.title-main {
  color:  #0a0a4e;
  font-size: 22px;
  font-weight: bold;
}


.title-desc {
  color:  #0a0a4e;
  font-size: 16px;
  font-weight: bold;
}

#discord {
  border-radius: 16px;
  background-image: linear-gradient(to bottom, #2d6cd0, #527cff);
}

#discord-background {
  background-image: url('../public/mask1.png');
  background-repeat: no-repeat;
  background-position: right 50%;
  background-size: contain;
  padding: 30px;
}

#explanation {
  border-radius: 16px;
  background-color: #6ec7ff;
}

#explanation-background {
  background-image: url("../public/mask2.png");
  background-repeat: no-repeat;
  background-position: right 50%;
  background-size: contain;
  padding: 30px;
}

#balance {
  border-radius: 16px;
  background-color: #fff;
}

.freeze {
  border-radius: 25px 25px 16px 16px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.freeze-background {
  height: 65px;
  background-image: url("../public/mask4.png");
  background-repeat: repeat-x;
  background-size: cover;
  background-position-x: -30px;
  border-radius: 17px 17px 0px 0px;
}


.frozen {
  border-radius: 16px;
  background-color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.connect {
  border-radius: 6.5px;
  background-color: #306bff;
}

.pop-card {
  width: 80px;
  height: 80px;
  display: inline-block;
  background: #F5F5F5;
  border-radius: 16px;
  margin-left: 2px;
  margin-right: 2px;
  background-repeat: no-repeat;
}

.pop {
  display: inline;
}

.pop-card:hover {
  cursor: pointer;
  animation: pulse 0.3s linear 1.1;
}

.sub-mask:hover .time-left {
  visibility: visible;
}

.time-left {
  color: #306bff;
  font-size: 9px;
  font-weight: 700;
  border-radius: 0px 0px 0px 0px;
  background: white;
  height: 30px;
  opacity: 0.8;
  position: absolute;
  bottom: 0px;
  width: 100%;
  visibility: hidden;
}


.frozen-pop-ready {
  color: white;
  font-size: 12px;
  font-weight: bold;
}

.add-more {
  font-size: 9px;
  display: block;
  color: #ffff5d;
}

@keyframes pulse {
  25%  {transform: scale(0.9);}
  75%  {transform: scale(0.9);}
}

.mask {
  height: 100%;
  width: 100%;
}

.mask-selected {
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.4);
}

.mask-selected-check {
  background-image: url("../public/check.png");
  background-position: right 5px top 5px;
  background-repeat: no-repeat;
  border-radius: 16px;
  background-size: 25px;
  position: relative;
}

.sub-mask {
  position: relative;
  height: 100%;
  width: 100%;
}

.offline {
  background-color: #c0392b!important;
  background: #c0392b!important;
}

.mask-ready {
  background-image: url("../public/ice/ice-logo.svg");
  background-size: 20px;
  background-repeat: no-repeat;
  position: relative;
  height: 100%;
  width: 100%;
  background-position-x: 5px;
  background-position-y: 5px;
}

.pops {
  width: 100%;
  overflow-y: auto;
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;
  height: 315px;
}

.pops-confirmation {
  max-width: 400px;
  margin: auto;
  overflow-x: auto;
  display: inline;
  margin: auto;
  overflow-x: scroll;
  display: inline-block;
  width: 80%;
}

.pops-cards-image {
  height: 100%;
  width: 100%;
}

.my-pops-bloc {
  flex:  1;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.my-pops-actions {
  width: 100%;
  height: 67px;
  border-radius: 16px;
  background-color: #fff;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 5px;
  align-items: center;
}

.m-fadeOut {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
}

.m-fadeIn {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
}

.btn-blue {
  width: 207px;
  height: 48px;
  border-radius: 6.5px;
  background-color: #306bff;
  color: white;
  padding: 14px 20px 14px 20px;
  display: inline-block;
  position: relative;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
}

.btn-blue:hover {
  cursor: pointer;
  opacity: 0.9;
}

.frozen-top {
  display: flex;
  flex-direction: column;
  height: 65px;
}

.earnings {
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
}

.earnings a {
  font-size: 13px;
  color:  #0a0a4e;
  font-weight: 500;
}

.btn-red {
  width: 207px;
  height: 48px;
  border-radius: 6.5px;
  background-color: #c0392b;
  color: white;
  padding: 14px 20px 14px 20px;
  display: inline-block;
  position: relative;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  margin: 10px;
}

.btn-red:hover {
  cursor: pointer;
  opacity: 0.9;
}

.btn-outlined {
  width: 207px;
  height: 48px;
  margin: 24px 8px 0 0;
  border-radius: 6.5px;
  border: solid 1px #443df6;
  padding: 14px 20px 14px 20px;
  display: inline-block;
  position: relative;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  margin: 10px;
  color: #443df6;
}

.btn-outlined:hover {
  cursor: pointer;
  opacity: 0.5;
}

.btn-small {
  background-color: #306bff;
  border-radius: 6.5px;
}


.disabled {
  width: 207px;
  height: 48px;
  border-radius: 6.5px;
  background-color: #306bff;
  color: white;
  padding: 14px 20px 14px 20px;
  display: inline-block;
  position: relative;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
}

.sidenav-trigger {
  color:  #0a0a4e;
}

.confirmation-modal {
  width: 100%;
  border-radius: 16px;
  background-color: #fff;
  text-align: center;
  display: block;
  padding: 40px;
  height: auto;
}

.disabled {
  background-color: grey;
  cursor: not-allowed;
  pointer-events: none;
}

.subtitle {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}

.description {
  font-size: 14px;
  color: #fff;
}

.arrow {
  width: 38px;
  height: 38px;
  border: solid 1px #fff;
  border-radius: 50%;
  margin-top: 7px;
}

.arrow:hover {
  cursor: pointer;
  border: solid 2px #fff;
}

.arrow .material-icons {
  color: white;
  position:relative;
  top: calc(50% - 10px);
  left: calc(50% - 6px);
  font-size: 12px;
}

.balance-bloc {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 167px;
}

.balance-bloc .top {
  flex: 1;
  display: flex;
  flex-direction: row;
}


.balance-bloc .bottom {
  flex-grow: 1;
}

.balance-bloc .value {
  font-size: 22px;
  font-weight: bold;
  color: #0a0a4e;
  display: block;
}

.balance-bloc .ticker {
  font-size: 16px;
  font-weight: 500;
  color: #0a0a4e;
}

.balance-bloc .address {
  font-size: 17px;
  color: #202025;
  white-space: nowrap;
  overflow: hidden;
}

.balance-bloc .currency {
  flex:  1;
  display: inline-block;
  padding-top: 20px;
}

.balance-bloc .currency-logo-stx {
  height:  20px;
  width: 20px;
}

.balance-bloc .currency-logo-ice {
  height:  14px;
  width: 20px;
}

.balance-bloc .currency-value {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.balance-bloc .bottom {
  border-top: solid 1px #f0f0f0;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

#stx {
  border-right: solid 2px #f0f0f0;
}

#ice {
}

.card .card-image .card-title {
  color: #0a0a4e;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px;
  font-weight: 400;
  background-color: rgba(255, 255, 255, 0.5);
  text-align: center;
}

.card {
  cursor: pointer;
}

.page-footer {
  background-color: #2d6cd0;
}

.empty-title {
  display: block;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  color: #0a0a4e;
}

.empty-desc {
  display: block;
}

.empty-bloc {
}
/* Navbar */

.menu {
  color:  #0a0a4e;
  font-size: 14px;
  font-weight: 600;
}

.menu-white {
  color:  #ffffff;
  font-weight: 600;
}

#nav-white {
  background-color: #ffffff;
}

#nav {
  background-color: #306bff;
}

#nav-icon-white {
  color: white;
}

#nav-icon {
  color: #0a0a4e;
}

#logo-white span {
  color: white;
}

#logo span {
  color: #0a0a4e;
}
/* Homepage design */

.part1 {
  background-color: #306bff;
  display: flex;
  align-items: flex-end;
}

.part1-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 450px;
}

.part1-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 450px;
}

.no-margin {
  margin-bottom: 0px;
}

.part1-image {
  width: 100%;
  height: auto;
}

.white-text { 
  color: white;
 }

.btn-white {
  width: 200px;
  height: 48px;
  border-radius: 8px;
  background-color: #fff;
  color: #306bff;
  font-weight: bold;
  text-align: center;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.btn-white:hover {
  opacity: 0.9;
  cursor: pointer;
}

.title-main-home {
  font-size: 40px;
}

.how-desc {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #0a0a4e;
  display: inline-block;
  width: auto;
  font-weight: bold;
}

.home-title {
  font-size: 40px;
  color: #0a0a4e;
  font-weight: 600;
}

.home-desc {
  color: #0a0a4e;
  font-weight: 500;
}

.how-step {
  width: 58px;
  height: 58px;
  padding: 20px 24px 18px 25px;
  border-radius: 29px;
  background-color: #306bff;
  color: white;
  font-weight: bold;
  display: inline-block;
  margin-right: 20px;
}

.step {
  margin-top: 20px;
  border-radius: 30px;
  padding: 30px;
  cursor: pointer;
}

.step:hover {
  background-color: #fff;
}

.step-selected {
  background-color: #fff;
}

.step-image {
  position: absolute;
  text-align: center;
}

.step-image-bloc {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 600px;
}

@media all and (max-width: 600px)
{
 .part1-right {
    height: auto;
  }
}

/* Lookup */

.MuiTable-root .collection .collection-item {
  font-size: 10px;
  padding: 0px;
  border: none;
}

.MuiTable-root .collection {
  border: none;
}


.MuiPaper-root {
  box-shadow: 0 4px 20px 0 rgba(194, 194, 194, 0.4)!important;
  border-radius: 16px!important;
  border: none;
}

.MuiPaper-root input {
  border-bottom: solid 1px rgba(0,0,0,0.12)!important;
  box-shadow: none!important;
}


.MuiTableCell-root .MuiTableCell-footer {
  border: none;
}

.MuiInput-underline:after {
  border-bottom: none!important;
}

.MuiInput-underline:before {
  border-bottom: none!important;
  display: none;
}

.faq {
  box-shadow: 0 4px 20px 0 rgba(194, 194, 194, 0.4);
  border: none;
  border-radius: 16px;
  padding: 10px 0px 10px 0px;
  background-color: white;
}

.faq .collapsible-header {
  border: none!important;
  background-color: white;
  font-weight: 600;
}

.faq .collapsible {
  border: none!important;
}
